.music_waves {
  margin: 200px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.music_waves > span {
  display: block;
  position: relative;
  background: #000;
  height: 100%;
  width: 1rem;
  border-radius: 1rem;
  margin: 0 0.2rem;
  animation: waves 1.2s linear infinite;
}

.music_waves > span:nth-child(1) {
  animation-delay: 0s;
  background: #ff8c00;
}

.music_waves > span:nth-child(2) {
  animation-delay: 0.3s;
  background: #ffff00;
}

.music_waves > span:nth-child(3) {
  animation-delay: 0.6s;
  background: #26d53a;
}

.music_waves > span:nth-child(4) {
  animation-delay: 0.9s;
  background: #26e6a3;
}

.music_waves > span:nth-child(5) {
  animation-delay: 0.6s;
  background: #1da8e2;
}

.music_waves > span:nth-child(6) {
  animation-delay: 0.3s;
  background: #5916ba;
}

.music_waves > span:nth-child(7) {
  animation-delay: 0s;
  background: #d418d9;
}

@keyframes waves {
  50% {
    height: 20%;
  }

  100% {
    height: 100%;
  }
}
