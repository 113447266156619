@media only screen and (max-width: 400px) {
  .testimonial_content p {
    padding: 0 !important;
  }
}
.section_title {
  text-align: center;
  margin-bottom: 30px;
}
.main_area {
  padding: 84px 0 86px;
}
.section_title h2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 22px;
  font-family: "Lora", serif !important;
}

.section_title h2::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 2px;
  background: #222;
  bottom: 0;
  left: 50%;
  transform: translatex(-50%);
}
.single_testimonial {
  text-align: center;
}
.icon_img {
  margin: 45px 0;
}
.testimonial_content p {
  font-size: 17px;
  font-style: italic;
  margin-bottom: 0;
  padding: 0 147px;
  line-height: 30px;
}
.testimonial_content img {
  margin-top: 32px;
}
.testimonial_author {
  margin: 17px !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 16px !important;
  text-transform: uppercase;
  font-weight: 500 !important;
}
