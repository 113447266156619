@media only screen and (max-width: 400px) {
  .section_title {
    margin-top: 110px !important;
  }
  .shop_toolbar_wrapper {
    padding: 8px 10px;
    margin: 30px;
    max-width: 400px !important;
  }
}
@media only screen and (max-width: 840px) {
  .section_title {
    margin-top: 110px !important;
  }
  .shop_toolbar_wrapper {
    padding: 8px 10px;
    margin: 30px;
    max-width: 800px !important;
  }
}
.section_title {
  background: #f0f0f0;
  border-bottom: 1px solid #e1e1e1;
  padding: 50px 0;
  margin-top: 65px;
  text-align: center;
}
.shop_toolbar_wrapper {
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  margin: 30px;
  width: 70%;
}
.show_row {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  justify-content: center;
}
