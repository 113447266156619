@media only screen and (max-width: 840px) {
  .about_content {
    padding: 50px 20px !important;
    text-align: center;
  }
}
@media only screen and (max-width: 400px) {
  .about_content {
    padding: 20px 10px !important;
    text-align: center;
  }
  .row {
    flex-direction: column;
  }
  .single_chose {
    width: 100% !important;
    margin-bottom: 20px;
  }
}
.about_page_area {
  margin: 10px 0;
}
.about_section {
  max-width: 1200px;
  margin: auto;
  padding: 100px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
img {
  max-width: 100%;
  height: auto;
}
.about_content {
  padding-top: 26px;
  text-align: center;
}
.about_content h1 {
  display: inline-block;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 21px;
  font-family: "Lora", serif;
}
.about_content p {
  font-size: 14px;
  line-height: 26px;
  max-width: 900px;
  margin: 0 auto;
}
.about_signature {
  margin-top: 25px;
}
.choseus_area {
  margin-bottom: 100px;

  background-image: url(https://htmldemo.net/lukani/lukani/assets/img/about/about-us-policy-bg.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  padding: 100px 0 93px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
}
.single_chose {
  text-align: center;
  flex: 0 0 auto;
  width: 33.33333333%;
}
.chose_icone {
  margin-bottom: 23px;
  transition: 0.5s;
}
.chose_icone:hover {
  transform: scaleX(-1);
}
.chose_content h3 {
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 19px;
  font-family: "Lora", serif;
}
.chose_content p:last-child {
  margin-bottom: 0;
}
.chose_content p {
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: 1rem;
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
