@media only screen and (max-width: 840px) {
  .section_title {
    margin-top: 105px !important;
  }
  .payment_form {
    max-width: 700px !important;
  }
}
@media only screen and (max-width: 400px) {
  .payment_form {
    max-width: 300px !important;
  }
}
.payment_form {
  max-width: 1200px;
  padding: 20px 0;
  margin: 0 auto;
}
.section_title {
  background: #f0f0f0;
  border-bottom: 1px solid #e1e1e1;
  padding: 50px 0;
  margin-top: 65px;
  text-align: center;
}
.payment_form div {
  padding: 20px 0;
}

.payment_form button {
  border: none;
}

.button_checkout {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  width: 50%;
  color: rgb(255, 255, 255);
  background-color: #7fad39;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button_checkout:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  background: #7fad39;
  left: 0;
  top: 0;
  border-radius: 10px;
}
