@media only screen and (max-width: 400px) {
  .style_modal {
    width: 300px !important;
    padding: 20px !important;
  }
  .loading {
    top: calc(58%);
    left: calc(70%);
  }
}
.style_modal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: papayawhip;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 50px 100px;
}

.input_search {
  height: 46px;
  font-size: 16px;
  margin-top: 20px;
  padding-left: 20px;
  outline: none;
  border: 1px solid #b2b2b2;
  width: 90%;
  border-right-color: transparent;
}
.list_product_search {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 10px;
  border: 2px solid #000;
  width: 100%;
  max-height: 415px;
  overflow-y: scroll;
}
.list_product_search::-webkit-scrollbar {
  width: 5px;
}
.list_product_search::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.list_product_search::-webkit-scrollbar-thumb {
  background-image: linear-gradient(-45deg, #314202, #79a206);
  border-radius: 50px;
}

.loading {
  position: absolute;
  display: block;
  top: calc(40%);
  left: calc(70%);
  width: 30px !important;
  height: 30px !important;
}

.no-result {
  font-weight: 700;
  margin: 0;
}

.list_product_search span {
  color: #000;
}

.product_item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.product_item img {
  width: 100px;
  height: 100px;
}
.name {
  font-weight: bold;
  color: unset;
}
.name:hover {
  color: #79a206;
  cursor: pointer;
}
.price {
  font-weight: bold;
}
