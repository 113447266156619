@media only screen and (max-width: 400px) {
  .table_responsive table {
    width: 100%;
  }
  .coupon_area {
    flex-direction: column;
    align-content: center;
  }
  .cart_page_area {
    max-width: 100% !important;
    min-width: 200px !important;
  }
  .coupon_left,
  .coupon_right {
    width: 90% !important;
    margin: 20px 0 !important;
  }
}
@media only screen and (max-width: 840px) {
  .table_responsive table {
    width: 100%;
  }
  .coupon_left,
  .coupon_right {
    width: 90% !important;
    margin: 20px 0 !important;
  }
}
.cart_page_area {
  margin: 100px auto;
  margin-top: 200px;
  max-width: 1200px;
  min-width: 500px;
}
.table_responsive table thead {
  background: #e1e1e1;
}
.table_responsive table thead tr th {
  border-bottom: 3px solid #79a206;
  border-right: 1px solid #e1e1e1;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px;
  text-align: center;
}
.table_responsive table tbody tr td {
  border-right: 1px solid #e1e1e1;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  min-width: 150px;
}
.product_price {
  min-width: 130px;
  color: #222222;
  font-size: 16px;
  font-weight: 500;
}
.cart_submit {
  text-align: right;
  padding: 12px;
}
.cart_submit button {
  background: #222222;
  border: 0;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  height: 38px;
  line-height: 18px;
  padding: 10px 15px;
  text-transform: uppercase;
  transition: 0.3s;
  border-radius: 3px;
}
.cart_submit button:hover {
  background-color: #79a206;
}
.coupon_area {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0 auto;
  justify-content: center;
}
.coupon_left {
  width: 48%;
  margin-right: 30px;
}
.coupon_right {
  width: 49%;
}
.coupon_area h3 {
  color: #ffffff;
  line-height: 36px;
  padding: 5px 15px;
  background: #222222;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}
.coupon_inner {
  padding: 20px 25px;
}
.coupon_inner p {
  font-size: 14px;
  margin-bottom: 20px;
}
.coupon_inner button {
  background: #222222;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 18px 15px;
  text-transform: uppercase;
  border-radius: 3px;
  margin-top: 10px;
  width: 100%;
}
.coupon_inner button:hover {
  background-color: #79a206;
}
.cart_subtotal {
  display: flex;
  justify-content: space-between;
}
.cart_subtotal p {
  font-weight: 600;
  font-size: 14px;
}
.cart_amount {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.shipping_free {
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  border-radius: 3px;
  color: #222222;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
.shipping_free:hover {
  color: #79a206;
}
.checkout_btn {
  text-align: right;
}
.checkout_btn button {
  background: #79a206;
  color: #ffffff;
  font-size: 15px;
  padding: 10px 14px;
  line-height: 30px;
  font-weight: 500;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 0;
  width: 100%;
  margin: 10px 0;
}
.checkout_btn button:disabled {
  background: #ccc !important;
}
