@media only screen and (max-width: 400px) {
  .product_item {
    margin-left: 0px !important;
  }
  .section_title {
    margin-top: 110px !important;
  }
}
@media only screen and (max-width: 840px) {
  .product_item {
    margin: 15px !important;
  }
  .section_title {
    margin-top: 110px !important;
  }
}
.product_area {
  margin-bottom: 74px;
  margin-top: 50px;
}
.product_loading {
  width: 100%;
  min-height: 500px;
}

.list_product {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product_item {
  margin-bottom: 30px;
  margin-left: 30px;
  text-align: center;
}
.product_thumb {
  position: relative;
}
.product_thumb a img {
  width: 300px;
  margin: 0 auto;
}

.label_sale {
  top: 11px;
  left: 11px;
  text-transform: uppercase;
  color: white;
  background: #dc0f0f;
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  padding: 0 14px;
  text-align: center;
  display: block;
  border-radius: 22px;
}

.label_product span {
  position: absolute;
}
.product_content {
  margin-top: 22px;
  text-align: center;
}

.product_content h4 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
  cursor: pointer;
}

.product_content h4:hover {
  color: #79a206;
}

.price_box {
  margin-top: 16px;
}
.price_box span {
  line-height: 16px;
}
.old_price {
  text-decoration: line-through;
  font-weight: 400 !important;
  font-size: 12px !important;
  margin-left: 5px !important;
}
.current_price {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #79a206;
}
.add_cart {
  background: #79a206 !important;
  color: #ffffff !important;
  width: 100%;
  margin: 20px 0 !important;
}
.add_cart:hover {
  background: #222 !important;
}
.select_option {
  display: flex;
  align-items: center;
  min-width: 300px !important;
}

.section_title {
  background: #f0f0f0;
  border-bottom: 1px solid #e1e1e1;
  padding: 50px 0;
  margin-top: 65px;
  text-align: center;
}

.show_row {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  justify-content: center;
}
.product h2 {
  text-align: center;
}
.product {
  width: 1200px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  margin: 30px;
  max-width: 1200px !important;
}
