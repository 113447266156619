@media only screen and (max-width: 400px) {
  .contact_form input {
    width: 300px !important;
  }
  .contact_message {
    text-align: center;
    margin: 20px;
  }
}
.section_title {
  background: #f0f0f0;
  border-bottom: 1px solid #e1e1e1;
  padding: 50px 0;
  margin-top: 65px;
  text-align: center;
}
.contact_map {
  margin: 50px auto;
  text-align: center;
}
.contact_map iframe {
  border: 0;
}

.map_title {
  text-align: center;
  margin-bottom: 30px;
}

.map_title h2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 22px;
  font-family: "Lora", serif !important;
}

.map_title h2::before {
  position: absolute;
  content: "";
  width: 80px;
  height: 2px;
  background: #222;
  bottom: 0;
  left: 50%;
  transform: translatex(-50%);
}
.contact_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
}
.contact_message h3 {
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 25px;
}
.contact_message p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
  max-width: 550px;
}
.contact_form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}
.contact_right h3 {
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 25px;
}
.contact_form input {
  border: 1px solid #e1e1e1;
  height: 50px;
  background: #ffffff;
  width: 500px;
  padding: 0 20px;
  color: #757575;
}
.contact_form button:hover {
  background: #79a206;
}
.contact_form button {
  font-weight: 400;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  text-transform: capitalize;
  border: none;
  background: #222222;
  color: #ffffff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
}
