body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  height: 100%;
  
}

code {
  font-family: "Rubik", sans-serif;
}
