@media only screen and (max-width: 400px) {
  .user_main a {
    padding: 8px 23px !important;
  }
  .user_main h1 {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 840px) {
  .section_title {
    margin-top: 110px !important;
  }
}
.user_area {
  padding-bottom: 86px;
  padding-top: 250px;
}
.user_main {
  text-align: center;
}
.user_main h1 {
  font-size: 50px;
  font-weight: 700;
  color: #79a206;
  letter-spacing: 10px;
  line-height: 160px;
  margin: 0 0 83px;
}
.user_main h2 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}
.user_main p {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}
.user_main button {
  background: #79a206;
  color: #ffffff;
  font-size: 15px;
  padding: 3px 14px;
  line-height: 30px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 10px;
  width: 30%;
}
.user_main button:hover {
  background: #222222;
}
.user_main a {
  background: #222;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  padding: 8px 230px;
  line-height: 30px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0;
  width: 30%;
}
