@media only screen and (max-width: 400px) {
  .row {
    margin-left: 0 !important;
    justify-content: space-between;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;
  margin-top: 100px;
  overflow: hidden;
}

.icone {
  margin-right: 18px;
}
