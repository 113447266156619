@media only screen and (max-width: 840px) {
  .infor_order {
    flex-direction: column;
    align-items: center;
  }
  .billing_detail,
  .detail_order {
    width: 90% !important;
    padding: 15px !important;
  }
  .section_title {
    margin-top: 105px !important;
  }
}
.section_title {
  background: #f0f0f0;
  border-bottom: 1px solid #e1e1e1;
  padding: 50px 0;
  margin-top: 65px;
  text-align: center;
}
.checkout_form {
  max-width: 1200px;
  margin: auto;
}
.checkout_form h3 {
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #ffffff;
  background: #222222;
  font-weight: 500;
}
.checkout_form span {
  color: #79a206;
  margin-left: 5px;
  font-style: italic;
}
.infor_order {
  margin: 20px 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.billing_detail,
.detail_order {
  width: 50%;
  padding: 0 15px;
}
.form_group {
  margin-bottom: 20px;
}
.form_group label {
  display: block;
  font-weight: 600;
}

.form_group :is(input, select) {
  width: 100%;
  height: 46px;
  border: 1px solid #ebebeb;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  padding-left: 10px;
}

.form_group select {
  background-color: unset;
  cursor: pointer;
}
.error {
  color: red !important;
  display: block;
}
.order_table table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
}
.order_table table thead {
  background: #e1e1e1;
}
.order_table table thead tr th {
  min-width: 50%;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}
.order_table table tbody tr td {
  border-right: 1px solid #e1e1e1;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;
  min-width: 150px;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}
.order_table table tfoot tr td {
  min-width: 50%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
}
.button_order {
  cursor: pointer;
  font-size: 16px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background: #79a206;
  font-weight: 500;
  margin: 30px 0;
  border-radius: 5px;
  border: none;
  width: 100%;
}
