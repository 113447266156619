@media only screen and (max-width: 400px) {
  .slider_section {
    height: 600px !important;
    text-align: center;
  }
  .single_slider {
    height: 400px !important;
    width: fit-content;
  }
  .slider_content h1 {
    font-size: 29px !important;
    line-height: 9px !important;
  }
  .slider_content {
    top: 30% !important;
    left: 10% !important;
  }
}
@media only screen and (max-width: 840px) {
  .slider_content {
    top: 20% !important;
    left: 10% !important;
  }
}
.slider_section {
  height: 950px;
  position: relative;
  background-repeat: no-repeat !important;
  background-attachment: scroll !important;
  background-position: center center !important;
  background-size: cover !important;
}
.single_slider {
  height: 738px;
}
.slider_content {
  position: absolute;
  top: 20%;
  left: 20%;
}
.slider_content a {
  font-size: 15px;
  font-weight: 500;
  padding: 0 45px;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 30px;
  height: 65px;
  line-height: 65px;
  background: inherit;
  margin-top: 46px;
  color: #fff;
  background: #222222;
}
.slider_content a:hover {
  background: #79a206;
}
.slider_content h1 {
  font-size: 90px;
  line-height: 90px;
  font-weight: 500;
  margin-bottom: 33px;
  text-transform: uppercase;
  letter-spacing: -2px;
}
.desc {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
  border: 0 !important;
  display: inline !important;
}
.slider_content span {
  color: #222222;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  width: 120px;
  display: inline-block;
  margin-bottom: 75px;
  border-left: 2px solid #000000;
  padding-left: 8px;
}
