body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background-color: #fafafa;
}
body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(-45deg, #314202, #79a206);
  border-radius: 50px;
}

*::selection {
  background-color: #79a206;
}

@import "toastr";
