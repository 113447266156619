@media only screen and (max-width: 400px) {
  .main_menu {
    font-size: 15px;
    text-align: center;
    width: 100% !important;
  }
  .row {
    display: flex;
    flex-direction: column !important;
  }
  .header_right {
    margin-left: 0 !important;
  }
  .sticky_header {
    max-width: 390px !important;
  }
  .hamburger {
    font-size: large;
    position: fixed !important;
    right: 2% !important;
  }
}
@media only screen and (max-width: 840px) {
  .main_menu {
    text-align: center;
    width: 100% !important;
  }
  .row {
    display: flex;
    flex-direction: column !important;
  }
  .header_right {
    margin-left: 0 !important;
  }
  .sticky_header {
    max-width: 840px !important;
  }
}

.sticky_header {
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11);
  border-bottom: 0;
  display: block;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.main_menu {
  margin: 0px;
}
.main_menu a {
  margin-right: 17px;
  color: #79a206;
  font-weight: 500;
}

.logo {
  max-width: 150px;
  margin-left: 0px;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
}
.header_right {
  text-align: right;
  padding: 0 20px;
}
.header_right a {
  font-size: 24px !important;
  margin-left: 20px;
  color: #222;
  font-weight: 500;
  padding: 0px;
  display: inline;
  margin-top: 46px;
}
.header_right a:hover {
  color: #79a206;
}
.header_account_list {
  margin-left: 27px;
}
.header_cart {
  margin-left: 10px !important;
  color: #222 !important;
}
.header {
  background-color: #ffffff !important;
}
.drawerContainer {
  padding: 20px 30px;
}
.hamburger {
  font-size: large;
  position: fixed !important;
  right: 20%;
}
.main_menu_drawer {
  display: flex;
  flex-direction: column;
  height: 300px;
  padding-top: 30px;
}
.main_menu_drawer a {
  margin-right: 20px;
  color: #79a206;
  font-weight: 500;
  padding: 20px;
}
